
<template>
    <b-container fluid>
      <iq-card>
        <template v-slot:body>
          <b-row>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.fiscal_year')"
              label-for="fiscal_year_id"
              >
              <b-form-select
              plain
              v-model="search.fiscal_year_id"
              :options="fiscalYearList"
              id="fiscal_year_id"
              >
              <template v-slot:first>
                  <b-form-select-option :value="0">{{ $t('globalTrans.select') }}</b-form-select-option>
              </template>
              </b-form-select>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-form-group
              class="row"
              label-cols-sm="3"
              :label="$t('bsri_demandDIS.allocation_id')"
              label-for="allocation_id"
              >
              <b-form-input
              v-model="search.allocation_id"
              >
              </b-form-input>
              </b-form-group>
            </b-col>
            <b-col xs="12" sm="12" md="6">
              <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
            </b-col>
          </b-row>
        </template>
      </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{ $t('bsri_demandDIS.sub_pro_appr') }} {{ $t('globalTrans.list') }} </h4>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="bg-primary" :emptyText="$t('globalTrans.noDataFound')" show-empty bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null">
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(allocation_id)="data">
                                            {{ $n(data.item.allocation_id, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(total_lands)="data">
                                            {{ $n(data.item.total_lands) }}
                                        </template>
                                        <template v-slot:cell(total_farmers)="data">
                                            {{ $n(data.item.total_farmers) }}
                                        </template>
                                         <template v-slot:cell(verfied)="data">
                                            <span class="badge badge-success" v-if="data.item.verfied == 1">{{$t('bsri_demandDIS.done')}}</span>
                                            <span class="badge badge-success" v-else>{{$t('bsri_demandDIS.not_done')}}</span>
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <b-button v-b-modal.modal-details variant=" iq-bg-success mr-1 mb-1" size="sm" @click="details(data.item)"><i class="ri-eye-line m-0"></i></b-button>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-details" size="xl" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Details :id="editItemId" :fiscalYearId="fiscalYearId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import Details from './Detail'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { subSidyProApprovalList } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    Details
  },
  data () {
    return {
      search: {
        fiscal_year_id: 0,
        allocation_id: ''
      },
      fiscalYearId: '',
      rows: [],
      item: ''
    }
  },
  mounted () {
  },
  computed: {
    fiscalYearList: function () {
        const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        return fiscalYearList.map(item => {
            if (this.$i18n.locale === 'bn') {
                return { value: item.value, text: item.text_bn }
            } else {
                return { value: item.value, text: item.text_en }
            }
        })
    },
    formTitle () {
       return (this.editItemId === 0) ? this.$t('bsri_demandDIS.sub_pro_appr') : this.$t('bsri_demandDIS.sub_pro_appr')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.fiscal_year'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.allocation_id'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.total_farmer'), class: 'text-left' },
          { label: this.$t('bsri_demandDIS.total_subsidized_land'), class: 'text-left' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_bn' },
          { key: 'allocation_id' },
          { key: 'total_farmers' },
          { key: 'total_lands' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'fiscal_year_en' },
          { key: 'allocation_id' },
          { key: 'total_farmers' },
          { key: 'total_lands' },
          { key: 'action' }
        ]
      }
      return labels.map((item, index) => {
        return Object.assign(item, keys[index])
      })
    }
  },
  watch: {
    loadingState: function (newVal, oldVal) {
      if (newVal) {
        this.loadData()
      }
    }
  },
  created () {
   this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    details (item) {
      this.editItemId = item.id
      this.item = item
      this.fiscalYearId = item.fiscal_year_id
    },
    edit (item) {
      this.editItemId = item.id
      this.fiscalYearId = item.fiscal_year_id
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(incentiveGrantServiceBaseUrl, subSidyProApprovalList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.getRelatinalData(response.data.data))
          this.paginationData(response.data)
        } else {
          this.$store.dispatch('setList', [])
        }
        this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
      })
    },
    getRelatinalData (data) {
      const fiscalYearList = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const listData = data.map(item => {
        const fiscalYearObj = fiscalYearList.find(fiscalYear => fiscalYear.value === item.fiscal_year_id)
        const fiscalYearData = { fiscal_year_en: fiscalYearObj.text_en, fiscal_year_bn: fiscalYearObj.text_bn }
        return Object.assign({}, item, fiscalYearData)
      })
      return listData
    }
  }
}
</script>
