<template>
    <b-container fluid>
        <b-row>
            <b-col lg="12" sm="12">
                <iq-card>
                    <template v-slot:body>
                        <b-overlay :show="loading">
                            <b-row>
                                <b-col lg="12" sm="12">
                                    <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                                        <b-form  @submit.prevent="handleSubmit(saveData)" @reset.prevent="reset">
                                            <b-row>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Farmers NID" vid="farmers_nid">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmers_nid"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.allocation_id')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmers_nid"
                                                            v-model="demandCollection.allocation_id"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            disabled
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="6" lg="6" xl="6">
                                                    <ValidationProvider name="Farmer's name" vid="farmer_name">
                                                        <b-form-group
                                                            class="row"
                                                            label-cols-sm="4"
                                                            label-for="farmer_name"
                                                            slot-scope="{ valid, errors }"
                                                        >
                                                        <template v-slot:label>
                                                            {{$t('bsri_demandDIS.fiscal_year')}}
                                                        </template>
                                                            <b-form-input
                                                            id="farmer_name"
                                                            v-model="demandCollection.fiscal_year"
                                                            :state="errors[0] ? false : (valid ? true : null)"
                                                            disabled
                                                            ></b-form-input>
                                                            <div class="invalid-feedback">
                                                            {{ errors[0] }}
                                                            </div>
                                                        </b-form-group>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <template>
                                                        <div style="font-size:18px; background-color: #66CC99;" class="mb-3">
                                                            <h5 class="text-white"> {{ $t('bsri_demandDIS.mill_information') }}</h5>
                                                        </div>
                                                    </template>
                                                </b-col>
                                                <b-col xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <b-table-simple class="table table-bordered" responsive>
                                                        <thead>
                                                            <tr>
                                                                <th>{{ $t('globalTrans.sl_no') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.mill_name') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.total_farmer') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.total_subsidized_land') }}</th>
                                                                <th>{{ $t('bsri_demandDIS.proposed_amount') }}</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            <tr v-for="(item, index) in millDetails" :key="index">
                                                                <td>{{ $n(index + 1) }}</td>
                                                                <td>{{ $i18n.locale === 'bn' ? item.mill_name_bn : item.mill_name }}</td>
                                                                <td>{{ $n(item.total_farmers) }}</td>
                                                                <td>{{ $n(item.total_lands) }}</td>
                                                                <td>{{ $n(item.total_lands) }}</td>
                                                            </tr>
                                                        </tbody>
                                                    </b-table-simple>
                                                </b-col>
                                                <b-col  xs="12" sm="12" md="12" lg="12" xl="12">
                                                    <b-table-simple class="table" bordered responsive>
                                                        <tr>
                                                            <th>{{$t('bsri_demandDIS.comments_en')}}</th>
                                                            <td>{{ demandCollection.comments_en }}</td>
                                                        </tr>
                                                        <tr>
                                                            <th>{{$t('bsri_demandDIS.comments_bn')}}</th>
                                                            <td>{{ demandCollection.comments_bn }}</td>
                                                        </tr>
                                                    </b-table-simple>
                                                </b-col>
                                            </b-row>
                                            <b-row>
                                                <b-col>
                                                    <div class="col text-right">
                                                        <b-button variant="success" class="mr-1" @click="$bvModal.hide('modal-details')">{{ $t('globalTrans.cancel') }}</b-button>
                                                    </div>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </ValidationObserver>
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import RestApi, { incentiveGrantServiceBaseUrl } from '@/config/api_config'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { subSidyProApprDetails } from '../../api/routes'

export default {
    props: ['id', 'fiscalYearId'],
    components: {
        ValidationObserver,
        ValidationProvider
    },
    data () {
        return {
            loading: false,
            saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
            demandCollection: {
                comments_en: '',
                comments_bn: '',
                fiscal_year_id: this.fiscalYearId
            },
            millDetails: []
        }
    },
    created () {
        if (this.fiscalYearId) {
            this.getEditingData()
            this.loadData()
        }
    },
    watch: {
    },
    mounted () {
        core.index()
    },
    computed: {
        locale: function () {
            return this.$i18n.locale
        },
        checklists: function () {
            const checklist = this.$store.state.incentiveGrant.commonObj.checkList.filter(item => item.fiscal_year_id === this.demandCollection.fiscal_year_id)
            return checklist.map(item => {
                if (this.$i18n.locale === 'bn') {
                    return { checklist: item.checklist_id, value: item.value, text: item.text_bn, desc: item.description_bn }
                } else {
                    return { checklist: item.checklist_id, value: item.value, text: item.text_en, desc: item.description_en }
                }
            })
        }
    },
    methods: {
        getEditingData () {
            const tmpData = this.$store.state.list.find(item => item.id === this.id)
            const data = JSON.parse(JSON.stringify(Object.assign({}, tmpData)))
            this.demandCollection.fiscal_year = this.$i18n.locale === 'bn' ? data.fiscal_year_bn : data.fiscal_year_en
            this.demandCollection.allocation_id = data.allocation_id
            this.demandCollection.comments_en = data.comments
            this.demandCollection.comments_bn = data.comments_bn
        },
        loadData () {
            RestApi.getData(incentiveGrantServiceBaseUrl, `${subSidyProApprDetails}/${this.fiscalYearId}`).then(response => {
                if (response.success) {
                    this.millDetails = this.getRelatinalData(response.data)
                }
            })
        },
        getRelatinalData (data) {
            const millList = this.$store.state.incentiveGrant.commonObj.millInfoList.filter(item => item.status === 1)
            const listData = data.map(item => {
                const millInfoObj = millList.find(MillItem => MillItem.value === item.mill_info_id)
                const millInfoData = { mill_name: millInfoObj.text_en, mill_name_bn: millInfoObj.text_bn }
                return Object.assign({}, item, millInfoData)
            })
            return listData
        }
    }
}
</script>
